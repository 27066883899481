var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('z-card-modal',{staticClass:"card-container-recognition",attrs:{"persistent":"","title":_vm.$t('documentScanner.scanner_upload_title'),"opened":_vm.opened,"height":"auto"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('section',{staticClass:"main-content-instructions-modal"},[_c('div',{staticClass:"d-flex flex-column align-center my-4",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-center mb-2"},[_c('illustration-document-scan',{staticClass:"image-mobile"})],1),_c('h4',{staticClass:"body-text text-center"},[_vm._v(" "+_vm._s(_vm.$t("documentScanner.upload_image_modal_text1"))+" ")]),_c('p',{staticClass:"body2 text-center mt-2 px-8 mx-8"},[_vm._v(" "+_vm._s(_vm.$t("documentScanner.upload_image_modal_text2"))+" ")]),_c('div',{staticClass:"d-flex mt-3"},[_c('section',{staticClass:"d-flex flex-column"},[(!_vm.guest.documents.front)?_c('z-btn',{staticClass:"my-2",attrs:{"primary":"","icon":"$attach_file_rounded","width":"90%","text":_vm.guest.documents.documentTypeId !== 0
                    ? _vm.$t('documentScanner.upload_image_modal_buttonFront')
                    : _vm.$t('documentScanner.z-chip-certidao')},on:{"click":_vm.onSelectImageFront}}):_c('uploaded-card',{staticClass:"upload-card",attrs:{"image-source":_vm.guest.documents.documentTypeId !== 0
                    ? 'documento_frente.jpg'
                    : 'certidao_nascimento.jpg',"image":_vm.guest.documents.frontImageMemory || _vm.guest.documents.front,"enable-close":true},on:{"click:erase":function($event){return _vm.$emit('erase:front-picture')}}}),_c('input',{ref:"imageInputFront",staticStyle:{"display":"none"},attrs:{"id":"imageInput","type":"file"}})],1),_c('section',{staticClass:"d-flex flex-column",staticStyle:{"width":"50%"}},[(!_vm.guest.documents.back)?_c('z-btn',{staticClass:"my-2 mx-2",attrs:{"primary":"","icon":"$attach_file_rounded","width":"90%","text":_vm.guest.documents.documentTypeId !== 0
                    ? _vm.$t('documentScanner.upload_image_modal_buttonBack')
                    : _vm.$t('documentScanner.z-chip-autorizacao')},on:{"click":_vm.onSelectImageBack}}):_vm._e(),(_vm.guest.documents.back)?_c('uploaded-card',{staticClass:"upload-card",attrs:{"image-source":_vm.guest.documents.documentTypeId !== 0
                    ? 'documento_verso.jpg'
                    : 'autorizacao_viagem.jpg',"image":_vm.guest.documents.backImageMemory || _vm.guest.documents.back,"enable-close":true},on:{"click:erase":function($event){return _vm.$emit('erase:back-picture')}}}):_vm._e(),_c('input',{ref:"imageInputBack",staticStyle:{"display":"none"},attrs:{"id":"imageInput","type":"file"}})],1)])]),_c('div',{staticClass:"buttons-section"},[_c('z-btn',{staticClass:"mx-2",attrs:{"width":"120px","text":_vm.$t('documentScanner.upload_image_modal_button_cancel')},on:{"click":function($event){return _vm.$emit('click:cancel')}}}),_c('z-btn',{staticClass:"mx-2",attrs:{"primary":"","width":"120px","text":_vm.$t('documentScanner.upload_image_modal_button_continue'),"disabled":_vm.guest.documents.frontImageMemory === '' ||
              _vm.guest.documents.backImageMemory === ''},on:{"click":function($event){return _vm.$emit('click:continue')}}})],1)])]},proxy:true}],null,false,3760656801)}):(_vm.opened)?_c('z-card',{staticClass:"card-container-upload",staticStyle:{"position":"absolute","top":"0","height":"100vh","left":"0","bottom":"0"},attrs:{"opened":_vm.opened},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('section',{staticClass:"mt-5"},[_c('div',{staticClass:"text-center mb-2"},[_c('illustration-document-scan'),_c('h4',{staticClass:"body-text text-center mt-4"},[_vm._v(" "+_vm._s(_vm.$t("reservation.scanner_instruction_title"))+" ")]),_c('div',{staticClass:"my-6",staticStyle:{"width":"280px","border":"1px solid #333333","margin":"0 auto"}})],1)]),_c('div',{staticClass:"btn-container-mobile"},[(!_vm.guest.documents.front)?_c('z-btn',{staticClass:"my-2",attrs:{"primary":"","icon":"$attach_file_rounded","width":"90%","text":_vm.guest.documents.documentTypeId !== 0
              ? _vm.$t('documentScanner.upload_image_modal_buttonFront')
              : _vm.$t('documentScanner.z-chip-certidao')},on:{"click":_vm.onSelectImageFront}}):_c('uploaded-card',{staticClass:"align-self-start",attrs:{"image-source":_vm.guest.documents.documentTypeId !== 0
              ? 'documento_frente.jpg'
              : 'certidao_nascimento.jpg',"image":_vm.guest.documents.frontImageMemory || _vm.guest.documents.front,"enable-close":true},on:{"click:erase":function($event){return _vm.changeGuest({
              'documents.frontImageMemory': '',
              'documents.front': '',
            })}}}),(!_vm.guest.documents.back)?_c('z-btn',{staticClass:"my-2",attrs:{"primary":"","icon":"$attach_file_rounded","width":"90%","text":_vm.guest.documents.documentTypeId !== 0
              ? _vm.$t('documentScanner.upload_image_modal_buttonBack')
              : _vm.$t('documentScanner.z-chip-autorizacao')},on:{"click":_vm.onSelectImageBack}}):(
            _vm.guest.documents.back && _vm.guest.documents.documentTypeId !== 0
          )?_c('uploaded-card',{staticClass:"align-self-start",attrs:{"image-source":_vm.guest.documents.documentTypeId !== 0
              ? 'documento_verso.jpg'
              : 'autorizacao_viagem.jpg',"image":_vm.guest.documents.backImageMemory || _vm.guest.documents.back,"enable-close":true},on:{"click:erase":function($event){return _vm.changeGuest({
              'documents.backImageMemory': '',
              'documents.back': '',
            })}}}):_vm._e()],1),_c('div',{staticClass:"btn-container-mobile"},[_c('z-btn',{staticClass:"my-2",attrs:{"primary":"","width":"100%","text":_vm.$t('documentScanner.upload_image_modal_button_continue'),"disabled":_vm.guest.documents.frontImageMemory === '' ||
            _vm.guest.documents.backImageMemory === ''},on:{"click":function($event){return _vm.$emit('click:continue')}}}),_c('z-btn',{staticClass:"document-scan-btn mt-2",attrs:{"text":_vm.$t('documentScanner.upload_image_modal_button_back'),"width":"100%"},on:{"click":function($event){return _vm.$emit('click:cancel')}}})],1),_c('input',{ref:"imageInputFront",staticStyle:{"display":"none"},attrs:{"id":"imageInput","type":"file"}}),_c('input',{ref:"imageInputBack",staticStyle:{"display":"none"},attrs:{"id":"imageInput","type":"file"}})]},proxy:true}])}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }