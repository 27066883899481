<template>
  <z-card-modal
    width="1031px"
    height="450px"
    persistent
    :title="$t('guest.search_zipcode')"
    :opened="opened"
  >
    <template #content>
      <div class="d-flex align-center mb-5">
        <z-input
          class="label-adress mt-3 d-flex d-inline-flex"
          :label="$t('guest.address')"
          style="width: 40%"
          :placeholder="$t('guest.placeholder_address_example')"
          type="text"
          :value="cepSearch.address"
          :error-messages="getErrorMessages(v$.address)"
          @input="setCepSearch({ address: $event })"
        />
        <z-input
          class="label-adress mt-3 d-flex d-inline-flex"
          style="width: 35%"
          :label="$t('guest.city')"
          :placeholder="$t('guest.placeholder_city_example')"
          type="text"
          :value="cepSearch.city"
          :error-messages="getErrorMessages(v$.city)"
          @input="setCepSearch({ city: $event })"
        />
        <z-autocomplete
          class="label-adress mt-3 d-flex d-inline-flex"
          :label="$t('guest.state')"
          style="width: 25%"
          :placeholder="$t('guest.placeholder_state_example')"
          type="text"
          :value="cepSearch.uf"
          :items="statesOptions.addressForgetCep"
          item-text="uf"
          item-value="uf"
          :error-messages="getErrorMessages(v$.uf)"
          @change="setCepSearch({ uf: $event })"
        />
        <z-btn
          primary
          class="btn-search mb-4"
          :is-loading="isLoadingCeps"
          :disabled="v$.$dirty && v$.$invalid"
          :text="$t('guest.search')"
          @click="onSearchCeps"
        />
      </div>
      <z-scrollbar
        v-if="!isLoadingAddress && !hasError"
        class="cards-container"
        style="height: 245px"
      >
        <div v-for="(address, i) in addressResults" :key="i">
          <address-card
            :address="address"
            @click:use-cep="onClickUseCep(address)"
          />
        </div>
      </z-scrollbar>
      <div v-if="hasError" style="height: 245px">
        <p class="body-text mt-3">
          {{ $t("guest.error_search_cep") }}
        </p>
      </div>
      <div
        v-if="isLoadingAddress"
        class="d-flex justify-center align-center"
        style="height: 245px"
      >
        <z-loading />
      </div>
      <div class="d-flex justify-end mt-6">
        <z-btn
          :text="$t('reservation.cancel_button')"
          @click="$emit('click:back')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { computed, onMounted } from "vue"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"

import AddressCard from "../AddressCard.vue"
import { usePersonalInfoValidations } from "../useValidations"

export default {
  components: {
    AddressCard,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { cepSearch, addressResults, statesOptions } = useNamespacedState(
      "parameters",
      ["cepSearch", "addressResults", "statesOptions"]
    )

    const { setCepSearch } = useNamespacedMutations("parameters", [
      "setCepSearch",
    ])

    const { getErrorMessages, searchCepRules } = usePersonalInfoValidations({
      cepSearch,
    })

    const {
      getCEPFromAddressUsingViaCEP,
      isLoading: isLoadingCeps,
      hasError,
    } = useStoreAction("parameters", "getCEPFromAddressUsingViaCEP")

    const onSearchCeps = async () => {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        await getCEPFromAddressUsingViaCEP()
      }
    }

    const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])
    const { tryFillByZipCode, isLoading: isLoadingAddress } = useStoreAction(
      "guest",
      "tryFillByZipCode"
    )

    const onClickUseCep = async (address) => {
      changeGuest({
        zipCode: address.cep,
      })
      await tryFillByZipCode()
      emit("click:back")
    }

    const rules = computed(() => {
      return {
        ...searchCepRules.value,
      }
    })
    const v$ = useVuelidate(rules, cepSearch)
    onMounted(() => {
      v$.value.$reset()
    })

    return {
      cepSearch,
      addressResults,
      getCEPFromAddressUsingViaCEP,
      setCepSearch,
      statesOptions,
      onSearchCeps,
      onClickUseCep,
      hasError,
      getErrorMessages,
      isLoadingAddress,
      isLoadingCeps,
      v$,
    }
  },
}
</script>

<style lang="scss" scoped>
.label-adress {
  width: 60%;
  margin-right: 30px;
}

.btn-search {
  padding: 0 45px !important;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fit, 80px);
  grid-gap: 15px;
}
</style>
