<template>
  <div>
    <z-card-modal
      v-if="$vuetify.breakpoint.mdAndUp"
      persistent
      :title="$t('documentScanner.scanner_upload_title')"
      :opened="opened"
      height="auto"
      class="card-container-recognition"
    >
      <template #content>
        <section class="main-content-instructions-modal">
          <div style="width: 100%" class="d-flex flex-column align-center my-4">
            <div class="text-center mb-2">
              <illustration-document-scan
                class="image-mobile"
              ></illustration-document-scan>
            </div>
            <h4 class="body-text text-center">
              {{ $t("documentScanner.upload_image_modal_text1") }}
            </h4>
            <p class="body2 text-center mt-2 px-8 mx-8">
              {{ $t("documentScanner.upload_image_modal_text2") }}
            </p>
            <div class="d-flex mt-3">
              <section style="" class="d-flex flex-column">
                <z-btn
                  v-if="!guest.additionalDocument.front"
                  class="my-2"
                  primary
                  icon="$attach_file_rounded"
                  width="90%"
                  :text="
                    guest.additionalDocument.documentTypeId !== 0
                      ? $t('documentScanner.upload_image_modal_buttonFront')
                      : $t('documentScanner.z-chip-certidao')
                  "
                  @click="onSelectImageFront"
                />
                <uploaded-card
                  v-else
                  class="upload-card"
                  :image-source="
                    guest.additionalDocument.documentTypeId !== 0
                      ? 'documento_frente.jpg'
                      : 'certidao_nascimento.jpg'
                  "
                  :image="guest.additionalDocument.frontImageMemory"
                  :enable-close="true"
                  @click:erase="$emit('erase:front-picture')"
                />
                <input
                  id="imageInput"
                  ref="imageInputFront"
                  type="file"
                  style="display: none"
                />
              </section>

              <section style="width: 50%" class="d-flex flex-column">
                <z-btn
                  v-if="!guest.additionalDocument.back"
                  class="my-2 mx-2"
                  primary
                  icon="$attach_file_rounded"
                  width="90%"
                  :text="
                    guest.additionalDocument.documentTypeId !== 0
                      ? $t('documentScanner.upload_image_modal_buttonBack')
                      : $t('documentScanner.z-chip-autorizacao')
                  "
                  @click="onSelectImageBack"
                />
                <uploaded-card
                  v-if="guest.additionalDocument.back"
                  class="upload-card"
                  :image-source="
                    guest.additionalDocument.documentTypeId !== 0
                      ? 'documento_verso.jpg'
                      : 'autorizacao_viagem.jpg'
                  "
                  :image="guest.additionalDocument.backImageMemory"
                  :enable-close="true"
                  @click:erase="$emit('erase:back-picture')"
                />
                <input
                  id="imageInput"
                  ref="imageInputBack"
                  type="file"
                  style="display: none"
                />
              </section>
            </div>
          </div>

          <div class="buttons-section">
            <z-btn
              class="mx-2"
              width="120px"
              :text="$t('documentScanner.upload_image_modal_button_cancel')"
              @click="$emit('click:cancel')"
            />
            <z-btn
              class="mx-2"
              primary
              width="120px"
              :text="$t('documentScanner.upload_image_modal_button_continue')"
              :disabled="
                guest.additionalDocument.frontImageMemory === '' ||
                guest.additionalDocument.backImageMemory === ''
              "
              @click="$emit('click:continue')"
            />
          </div>
        </section>
      </template>
    </z-card-modal>
    <z-card
      v-else-if="opened"
      :opened="opened"
      style="position: absolute; top: 0; height: 100vh; left: 0; bottom: 0"
      class="card-container-upload"
    >
      <template #content>
        <section class="mt-5">
          <div class="text-center mb-2">
            <illustration-document-scan></illustration-document-scan>

            <h4 class="body-text text-center mt-4">
              {{ $t("reservation.scanner_instruction_title") }}
            </h4>
            <div
              class="my-6"
              style="width: 280px; border: 1px solid #333333; margin: 0 auto"
            ></div>
          </div>
        </section>
        <div class="btn-container-mobile">
          <z-btn
            v-if="!guest.additionalDocument.front"
            class="my-2"
            primary
            icon="$attach_file_rounded"
            width="90%"
            :text="
              guest.additionalDocument.documentTypeId !== 0
                ? $t('documentScanner.upload_image_modal_buttonFront')
                : $t('documentScanner.z-chip-certidao')
            "
            @click="onSelectImageFront"
          />
          <uploaded-card
            v-else
            class="align-self-start"
            :image-source="
              guest.additionalDocument.documentTypeId !== 0
                ? 'documento_frente.jpg'
                : 'certidao_nascimento.jpg'
            "
            :image="guest.additionalDocument.frontImageMemory"
            :enable-close="true"
            @click:erase="
              changeGuest({
                'additionalDocument.frontImageMemory': '',
                'additionalDocument.front': '',
              })
            "
          />

          <z-btn
            v-if="!guest.additionalDocument.back"
            class="my-2"
            primary
            icon="$attach_file_rounded"
            width="90%"
            :text="
              guest.additionalDocument.documentTypeId !== 0
                ? $t('documentScanner.upload_image_modal_buttonBack')
                : $t('documentScanner.z-chip-autorizacao')
            "
            @click="onSelectImageBack"
          />
          <uploaded-card
            v-else-if="
              guest.additionalDocument.back &&
              guest.additionalDocument.documentTypeId !== 0
            "
            class="align-self-start"
            :image-source="
              guest.additionalDocument.documentTypeId !== 0
                ? 'documento_verso.jpg'
                : 'autorizacao_viagem.jpg'
            "
            :image="guest.additionalDocument.backImageMemory"
            :enable-close="true"
            @click:erase="
              changeGuest({
                'additionalDocument.backImageMemory': '',
                'additionalDocument.back': '',
              })
            "
          />
        </div>
        <div class="btn-container-mobile">
          <z-btn
            class="my-2"
            primary
            width="100%"
            :text="$t('documentScanner.upload_image_modal_button_continue')"
            :disabled="
              guest.additionalDocument.frontImageMemory === '' ||
              guest.additionalDocument.backImageMemory === ''
            "
            @click="$emit('click:continue')"
          />
          <z-btn
            class="document-scan-btn mt-2"
            :text="$t('documentScanner.upload_image_modal_button_back')"
            width="100%"
            @click="$emit('click:cancel')"
          />
        </div>
        <input
          id="imageInput"
          ref="imageInputFront"
          type="file"
          style="display: none"
        />
        <input
          id="imageInput"
          ref="imageInputBack"
          type="file"
          style="display: none"
        />
      </template>
    </z-card>
  </div>
</template>

<script>
import { ref } from "vue"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { readFile } from "@/composables/useParser"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { guest } = useNamespacedState("guest", ["guest"])
    const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])

    const imageInputFront = ref(null)
    const imageInputBack = ref(null)

    const onSelectImageFront = () => {
      imageInputFront.value.click()
      imageInputFront.value.onchange = async (e) => {
        let fileFront = e.target.files[0]

        if (!fileFront) return

        const imageFront = await readFile(fileFront)
        emit("change:documents", {
          front: imageFront,
        })
        changeGuest({
          currentImage: "imageFront",
          "additionalDocument.frontImage": fileFront.name,
        })
      }
    }

    const onSelectImageBack = () => {
      imageInputBack.value.click()
      imageInputBack.value.onchange = async (e) => {
        let fileBack = e.target.files[0]

        if (!fileBack) return

        const imageBack = await readFile(fileBack)
        emit("change:documents", {
          back: imageBack,
        })
        changeGuest({
          currentImage: "imageBack",
          "additionalDocument.backImage": fileBack.name,
        })
      }
    }

    return {
      guest,
      changeGuest,

      onSelectImageFront,
      onSelectImageBack,
      imageInputBack,
      imageInputFront,
    }
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 70%;
  margin: 0 auto;
}

.main-content-instructions-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-card {
  margin: 8px 10px 0 5px;
  width: 260px !important;
}

.buttons-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.body-text {
  padding: 0 20px;
  margin: 0 10px;
}
.card-container-upload {
  border-radius: 0 !important;
  top: 0;
  width: 100% !important;
  height: 90%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 20px;
  align-self: center;
}

.btn-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 90%;
  align-self: center;
}

@media screen and (max-width: 768px) {
  img {
    width: 30%;
  }
}
</style>
