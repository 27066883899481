<template>
  <z-card width="100%" class="main-desktop-content">
    <template #content>
      <h3>{{ $t("guest.personal_info") }}</h3>
      <p class="body-text grid-span-2">
        {{ $t("guest.obligation_requested_infos") }}
        <a @click="$emit('click:openmodal')">{{
          $t("guest.national_registration_form")
        }}</a>
        {{ $t("guest.opitional_infos_sinalized") }}
      </p>

      <guest-avatar
        :photo="guest.photoUrlMemory || guest.photoUrl"
        size="128"
        @click:upload="$emit('click:upload')"
      />

      <div class="form-content">
        <div class="d-flex">
          <z-checkbox
            v-if="!guest.masterGuest && hasMasterGuestData(reservation.id)"
            :label="$t('guest.masterguest_data_utilize')"
            :value="utilizeMasterGuestData"
            hide-details
            @change="$emit('use:masterguest', $event)"
          />
          <z-checkbox
            v-if="reservation.childrenNumber > 0 && !guest.masterGuest"
            data-cy="is-child"
            class="ml-5"
            :label="$t('guest.this_guest_is_child')"
            :value="guest.isChild"
            hide-details
            @change="$emit('change', { isChild: $event })"
          />
        </div>

        <personal-data-section
          :guest="guest"
          :config="config"
          :jobs="jobs"
          :billed="isBilled"
          :can-edit-main-guest-name="canEditMainGuestName"
          :countries="countries"
          :v$="v$"
          :get-error-messages="getErrorMessages"
          @change="$emit('change', $event)"
          @change:watch-age="$emit('change:watch-age')"
          @blur="$emit('blur')"
        />

        <documents-section
          :guest="guest"
          :config="config"
          :v$="v$"
          :get-error-messages="getErrorMessages"
          @change="$emit('change', $event)"
          @click-scan-document="$emit('click-scan-document', $event)"
          @erase:back-picture="$emit('erase:back-picture')"
          @erase:front-picture="$emit('erase:front-picture')"
        />

        <contact-section
          :guest="guest"
          :config="config"
          :v$="v$"
          :countries="countries"
          :get-error-messages="getErrorMessages"
          @change="$emit('change', $event)"
        />

        <address-section
          :guest="guest"
          :config="config"
          :v$="v$"
          :cities="cities"
          :states="states"
          :get-error-messages="getErrorMessages"
          @change="$emit('change', $event)"
          @click:open-forgot-adress="onClickOpenForgotAddress"
        />
        <modal-forgot-adress
          v-if="isForgotAddressModalOpen"
          :opened="isForgotAddressModalOpen"
          @click:back="isForgotAddressModalOpen = false"
        />

        <div class="actions-container">
          <z-btn :text="$t('guest.back')" @click="$emit('click:back')" />
          <z-btn
            primary
            data-cy="next-btn"
            class="mb-6"
            :text="$t('guest.next')"
            :disabled="v$.$dirty && v$.$invalid"
            @click="onNext"
          />
        </div>
      </div>
    </template>
  </z-card>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { computed, onMounted, ref, toRefs } from "vue"
import { useNamespacedGetters } from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"
import { hasMasterGuestData } from "@/storage"

import ModalForgotAdress from "./modals/ModalForgotAdress.vue"
import AddressSection from "./sections/AddressSection.vue"
import ContactSection from "./sections/ContactSection.vue"
import DocumentsSection from "./sections/DocumentsSection.vue"
import PersonalDataSection from "./sections/PersonalDataSection.vue"
import { usePersonalInfoValidations } from "./useValidations"

export default {
  components: {
    AddressSection,
    ContactSection,
    DocumentsSection,
    PersonalDataSection,
    ModalForgotAdress,
  },
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
    guest: {
      type: Object,
      default: () => {},
    },
    reservation: {
      type: Object,
      default: () => {},
    },
    states: {
      type: Array,
      default: () => [],
    },
    cities: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
    utilizeMasterGuestData: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { guest } = toRefs(props)
    const { personalInfoConfig } = useNamespacedGetters("company", [
      "personalInfoConfig",
    ])
    const { getErrorMessages, personalDataRules, contactRules, addressRules } =
      usePersonalInfoValidations({
        config: props.config,
        guest,
      })

    const { getStates } = useStoreAction("parameters", "getStates")

    const isForgotAddressModalOpen = ref(false)

    const rules = computed(() => {
      return {
        ...personalDataRules.value,
        ...contactRules.value,
        ...addressRules.value,
      }
    })

    const v$ = useVuelidate(rules, guest)

    const onNext = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        emit("submit")
      }
    }

    onMounted(() => {
      v$.value.$reset()
    })

    const checkIfReservationIsBilled = () => {
      return (
        props.reservation?.billed === true && props.guest.masterGuest === true
      )
    }

    const isBilled = checkIfReservationIsBilled()

    const canEditMainGuestName =
      personalInfoConfig.value?.rules?.canEditMainGuestName === true ||
      (personalInfoConfig.value?.rules?.canEditMainGuestName === false &&
        guest.value.masterGuest === false)

    const onClickOpenForgotAddress = () => {
      getStates({ section: "addressForgetCep", countryCode: "BR" })
      isForgotAddressModalOpen.value = true
    }

    return {
      hasMasterGuestData,
      v$,
      getErrorMessages,
      onNext,
      onClickOpenForgotAddress,
      isForgotAddressModalOpen,
      isBilled,
      canEditMainGuestName,
    }
  },
}
</script>

<style lang="scss" scoped>
.main-desktop-content {
  display: grid;
  row-gap: 15px;
  grid-template-columns: 175px, 1fr;
  grid-template-rows: 80px, auto;
  width: 100% !important;
}
.v-input--selection-controls {
  margin-top: 6px;
}

.actions-container {
  display: grid;
  width: 100%;
  justify-content: end;
  column-gap: 15px;
  grid-template-columns: min-content min-content;
}
</style>
