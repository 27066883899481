<template>
  <z-tabs-card
    v-show="isModalOpened === false"
    class="personal-info-tabs"
    grow
    touchless
    :value="currentTab"
    :tabs="tabs"
    @change="currentTab = $event"
  >
    <template #dados>
      <personal-data
        v-if="currentTab === 'dados'"
        class="tab-content"
        :jobs="jobs"
        :countries="countries"
        :guest="guest"
        :config="config"
        :reservation="reservation"
        :utilize-master-guest-data="utilizeMasterGuestData"
        @use:masterguest="$emit('use:masterguest', $event)"
        @click:next="onNext"
        @click:back="onPrevious"
        @click:openmodal="$emit('click:openmodal')"
        @change="$emit('change', $event)"
        @click:scan-document="$emit('click-scan-document')"
        @click:upload="$emit('click:upload')"
        @click:upload-picture-mobile="$emit('click:upload-picture-mobile')"
        @change:watch-age="$emit('change:watch-age')"
        @blur="$emit('blur')"
      />
    </template>
    <template #contatos>
      <contacts
        v-if="currentTab === 'contatos'"
        class="tab-content"
        :guest="guest"
        :config="config"
        @click:next="onNext"
        @click:back="onPrevious"
        @change="$emit('change', $event)"
      />
    </template>
    <template #address>
      <user-address
        v-if="currentTab === 'address'"
        class="tab-content"
        :guest="guest"
        :cities="cities"
        :states="states"
        :config="config"
        @click:next="onNext"
        @click:back="onPrevious"
        @change="$emit('change', $event)"
        @click:cep="onClickOpenForgotAddress"
      />
    </template>
  </z-tabs-card>
</template>

<script>
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router/composables"
import goTo from "vuetify/lib/services/goto"

import { useStoreAction } from "@/composables"
import i18n from "@/i18n"

import UserAddress from "./tabs/Address.vue"
import Contacts from "./tabs/Contacts.vue"
import PersonalData from "./tabs/PersonalData.vue"

export default {
  components: { UserAddress, Contacts, PersonalData },
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    reservation: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    cities: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    defaultDocumentTypes: {
      type: Array,
      default: () => [],
    },
    foreignDocumentTypes: {
      type: Array,
      default: () => [],
    },
    minorsDocumentTypes: {
      type: Array,
      default: () => [],
    },
    defaultAdditionalTypes: {
      type: Array,
      default: () => [],
    },
    foreignAdditionalTypes: {
      type: Array,
      default: () => [],
    },
    minorsAdditionalTypes: {
      type: Array,
      default: () => [],
    },
    guest: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
      required: true,
    },
    initialTab: {
      type: String,
      default: "dados",
    },
    isModalOpened: {
      type: Boolean,
      default: false,
    },
    utilizeMasterGuestData: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()

    const currentTab = ref("dados")
    const { getStates } = useStoreAction("parameters", "getStates")

    const tabs = computed(() => {
      const tabsArr = [
        {
          id: "dados",
          text: i18n.tc("guest.personal_data"),
          icon:
            currentTab.value === "contatos" || currentTab.value === "address"
              ? "$check_rounded"
              : null,
          disabled: false,
        },
        {
          id: "contatos",
          text: i18n.tc("guest.contact"),
          icon: currentTab.value === "address" ? "$check_rounded" : null,
          disabled: currentTab.value === "dados",
        },
      ]

      if (
        !props.config.addressData?.zipcode?.default ||
        !props.config.addressData?.state?.default ||
        !props.config.addressData?.city?.default ||
        !props.config.addressData?.neighborhood?.default ||
        !props.config.addressData?.address?.default ||
        !props.config.addressData?.addressNumber?.default ||
        !props.config.addressData?.addressComplement?.default
      ) {
        tabsArr.push({
          id: "address",
          text: i18n.tc("guest.address"),
          disabled:
            currentTab.value === "dados" || currentTab.value === "contatos",
        })
      }

      return tabsArr
    })

    onMounted(() => {
      currentTab.value = props.initialTab
    })

    const onNext = () => {
      const index = tabs.value.findIndex((tab) => tab.id === currentTab.value)

      if (index + 1 < tabs.value.length) {
        goTo(0)
        currentTab.value = tabs.value[index + 1].id
        emit("next", currentTab.value)
      } else {
        emit("submit")
      }
    }

    const onPrevious = () => {
      const index = tabs.value.findIndex((tab) => tab.id === currentTab.value)
      if (index - 1 >= 0) {
        goTo(0)
        currentTab.value = tabs.value[index - 1].id
      } else if (index === 0) {
        router.push({ name: "home" })
      }
    }

    const onClickOpenForgotAddress = () => {
      getStates({ section: "addressForgetCep", countryCode: "BR" })
      router.push({ name: "forgot-address" })
    }
    return {
      currentTab,
      tabs,
      onClickOpenForgotAddress,

      onNext,
      onPrevious,
    }
  },
}
</script>

<style lang="scss" scoped>
.tab-content {
  padding: 12px;
}

.personal-info-tabs {
  max-width: calc(100vw - 26px);
}
</style>
