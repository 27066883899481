<!-- eslint-disable max-lines -->
<template>
  <div>
    <z-card-modal
      v-if="$vuetify.breakpoint.mdAndUp"
      :title="
        currentPicture === 'front'
          ? $t('documentScanner.scanner_modal_titleFront')
          : $t('documentScanner.scanner_modal_titleBack')
      "
      width="540px"
      persistent
      :opened="opened"
    >
      <template #content>
        <div class="d-flex justify-space-between mb-5">
          <p class="my-2 body3">
            {{ $t("documentScanner.scanner_modal_subtitle") }}
          </p>
          <section class="d-flex justify-space-between">
            <z-chip
              :text="
                guest.documents.documentTypeId !== 0
                  ? $t('documentScanner.z-chip_front')
                  : $t('documentScanner.z-chip-certidao-tab')
              "
              icon="$check_in_a_circle"
              data-cy="frente-chip"
              icon-right
              :selected="guest.documents.frontImageMemory ? true : false"
              :color="guest.documents.frontImageMemory ? 'green' : null"
              class="mr-2"
            />
            <z-chip
              :text="
                guest.documents.documentTypeId !== 0
                  ? $t('documentScanner.z-chip_back')
                  : $t('documentScanner.z-chip-autorizacao-tab')
              "
              data-cy="back-chip"
              icon="$check_in_a_circle"
              icon-right
              :selected="currentPicture === 'back' ? false : true"
              :color="currentPicture === 'back' ? null : 'grey'"
            />
          </section>
        </div>

        <div
          class="pass-hospitality document-scanner d-flex flex-column"
          :style="cropImageOpen ? 'height:388px' : ''"
        >
          <div class="camera-stream-video">
            <z-camera-stream ref="cameraStream" :stream="stream" />
          </div>
          <img
            class="shade-image"
            src="@/assets/images/shade-doc-scanner.svg"
          />
          <z-photo-upload
            v-if="cropImageOpen"
            ref="photoUpload"
            dashed
            crop-whole-image
            with-points
            :image="currentImage"
            class="crop-picture"
          />
          <div class="">
            <img
              v-if="!cropImageOpen"
              class="face-target"
              src="@/assets/images/mira-document-scanner.svg"
              alt="Document target"
            />
          </div>
        </div>
        <section class="d-flex justify-end mt-4 mb-2">
          <z-btn
            class="document-scan-btn mx-3"
            :text="$t('documentScanner.button_cancel')"
            :disabled="isGettingPermission"
            @click="$emit('close')"
          />
          <z-btn
            v-if="!cropImageOpen"
            data-cy="take-pic-btn"
            class="document-scan-btn"
            :text="actionBtnText"
            primary
            :disabled="isGettingPermission"
            @click="onScanClick"
          />
          <z-btn
            v-else
            class="document-scan-btn"
            data-cy="btn-crop-image"
            :text="$t('documentScanner.button_confirm')"
            primary
            @click="onClickCropImage"
          />
        </section>
      </template>
    </z-card-modal>
    <div
      v-else
      style="position: absolute; top: 0; height: 100vh; left: 0; bottom: 0"
      class="mobile-layout"
    >
      <div
        :opened="opened"
        class="pass-hospitality-mobile document-scanner-mobile"
      >
        <span
          class="close-btn-mobile align-self-end mr-3 mt-3"
          @click="$emit('close')"
        >
          <z-icon size="20px" color="white"> $close_rounded </z-icon></span
        >
        <img
          v-if="guest.documents.documentTypeId !== 0"
          class="shade-image-mobile"
          src="@/assets/images/shade-document-scanner.svg"
        />
        <img
          v-else
          class="shade-image-mobile"
          src="@/assets/images/Subtract-certidao.svg"
        />

        <z-tabs
          ref="scannerTabs"
          :class="
            guest.documents.documentTypeId !== 0
              ? 'document-scanner-tabs'
              : 'document-scanner-tabs certificate-birth'
          "
          android
          grow
          dark
          color="white"
          :tabs="tabOptions"
          :value="currentPicture"
          @change="currentPicture = $event"
        />
        <div
          v-if="cropImageOpen"
          ref="test"
          class="document-scan-instructions-crop-mobile body3"
        >
          {{ $t("documentScanner.scanner_mobile_instruction") }}
        </div>
        <document-camera ref="cameraStream" />
        <z-photo-upload
          v-if="cropImageOpen"
          ref="photoUpload"
          style="z-index: 3"
          crop-whole-image
          dashed
          with-points
          :image="currentImage"
        />
        <img
          v-if="!cropImageOpen && guest.documents.documentTypeId !== 0"
          class="document-target-mobile"
          src="@/assets/images/mira-document-scanner-mobile.svg"
          alt="Document target"
        />
        <img
          v-else
          class="target-document"
          :style="cropImageOpen ? 'visibility : hidden' : ''"
          src="@/assets/images/mira-certidao.svg"
          alt="Facial target"
        />

        <div
          v-if="!cropImageOpen"
          ref="test"
          class="document-scan-instructions-mobile body3"
        >
          {{ $t("documentScanner.scanner_mobile_instruction2") }}
        </div>
        <div v-else ref="test" class="document-scan-instructions-mobile body3">
          {{ $t("documentScanner.scanner_mobile_instruction3") }}
        </div>
        <z-btn
          v-if="!cropImageOpen"
          class="document-scan-btn-mobile"
          data-cy="button-scan-document-mobile"
          :text="$t('documentScanner.scanner_mobile_button_scan')"
          primary
          :is-loading="isGettingPermission"
          @click="onScanClickMobile"
        />
        <z-btn
          v-else
          class="document-scan-btn-mobile"
          data-cy="button-confirm-document-mobile"
          :text="$t('documentScanner.scanner_mobile_button_confirm')"
          primary
          @click="onClickCropImage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from "vue"
import { useNamespacedState } from "vuex-composition-helpers/dist"

import documentCamera from "@/components/TakePicture/components/DocumentCamera/DocumentCamera.vue"
import { useCamera } from "@/composables"
import i18n from "@/i18n"

export default {
  name: "DocumentScannerModal",
  components: {
    documentCamera,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    actionBtnText: {
      type: String,
      default: "Capturar",
    },
    isLoadingFace: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { hasPermission, stream, isGettingPermission } = useCamera(true)
    const { guest } = useNamespacedState("guest", ["guest"])
    const cameraStream = ref(null)
    const photoUpload = ref(null)
    const cropImageOpen = ref(false)
    const currentImage = ref("")
    const currentPicture = ref("front")

    const tabOptions = computed(() => {
      return [
        {
          id: "front",
          text:
            guest.value.documents.documentTypeId !== 0
              ? i18n.tc("documentScanner.z-chip_front")
              : i18n.tc("documentScanner.z-chip-certidao-tab"),
          icon: guest.value.documents.frontImageMemory
            ? "$check_in_a_circle"
            : "",
          iconColor: "green",
        },
        {
          id: "back",
          text:
            guest.value.documents.documentTypeId !== 0
              ? i18n.tc("documentScanner.z-chip_back")
              : i18n.tc("documentScanner.z-chip-autorizacao-tab"),
          icon: guest.value.documents.backImageMemory
            ? "$check_in_a_circle"
            : "",
          iconColor: "green",
        },
      ]
    })

    const onScanClickMobile = async () => {
      const image = await cameraStream.value.getPhoto()
      if (currentPicture.value === "front") {
        currentImage.value = image
        cropImageOpen.value = true
      } else if (currentPicture.value === "back") {
        currentImage.value = image
        cropImageOpen.value = true
      }
    }

    const onScanClick = async () => {
      const image = await cameraStream.value.getImage()
      if (currentPicture.value === "front") {
        currentImage.value = image
        cropImageOpen.value = true
      } else if (currentPicture.value === "back") {
        currentImage.value = image
        cropImageOpen.value = true
      }
    }

    const onClickCropImage = async () => {
      const croppedImage = photoUpload.value.getCroppedImage()

      if (currentPicture.value === "front") {
        emit("confirm-scanned-document", croppedImage, "front")
        currentPicture.value = "back"
        cropImageOpen.value = false
      } else if (currentPicture.value === "back") {
        emit("confirm-scanned-document", croppedImage, "back")

        cropImageOpen.value = false
      }
    }

    onMounted(() => {})
    return {
      onClickCropImage,
      cameraStream,
      hasPermission,
      isGettingPermission,
      stream,
      onScanClick,
      currentImage,
      guest,
      cropImageOpen,
      photoUpload,
      currentPicture,
      tabOptions,
      onScanClickMobile,
    }
  },
}
</script>

<style lang="scss">
.pass-hospitality {
  &.document-scanner {
    video {
      object-fit: cover;
      position: initial !important;
    }
  }
}
.camera-stream-video {
  height: 340px !important;
  .error-text {
    visibility: hidden !important;
  }
}
.pass-hospitality-mobile {
  &.document-scanner-mobile {
    video {
      height: 100vh;
      width: 100vw;
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.pass-hospitality {
  &.document-scanner {
    align-items: center;
    justify-content: center !important;
    height: 345px;
    background-color: #666666;
    position: relative;
    .document-scan-btn {
      width: 80%;
      height: 100%;
      justify-self: center;
      margin-bottom: 20px;
    }

    .shade-image {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      object-fit: cover;
      margin: auto;
    }
    .face-scan-instructions {
      display: flex;
      align-content: center;
      justify-content: center;
      text-align: center;
    }

    .face-scan-instructions-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .face-target {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      margin: auto;
      width: 90%;
    }

    .crop-picture {
      position: absolute;
      object-fit: cover;
      z-index: 3;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      height: 338px !important;
      > div {
        height: 338px !important;
      }
      .cropper-container {
        height: 100% !important;
      }
    }
  }

  .mobile-layout {
    display: grid;
    gap: 20px;
    justify-items: center;
    align-items: start;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100vw;
    overflow-y: auto;
    padding: 20px;
  }
}

.pass-hospitality-mobile {
  &.document-scanner-mobile {
    display: grid;
    place-items: center;
    place-content: center;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--black);
    padding: 20px;

    .target-document {
      position: absolute;
      max-height: 80vh;
      left: 0;
      right: 0;
      z-index: 2;
      margin: 0 auto;
      width: 95%;
      margin-top: 15px;
    }

    .close-btn-mobile {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding-right: 2px;
    }

    .shade-image-mobile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      background-position: center; /* Center the image */
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      z-index: 1;
    }
    .document-scanner-tabs {
      position: absolute;
      top: 15%;
      width: 100%;
      z-index: 2;
    }
    .certificate-birth {
      top: 8%;
    }

    .document-scan-btn-mobile {
      width: 80%;
      justify-self: center;
      bottom: 5%;
      position: absolute;
      z-index: 2;
    }

    .document-scan-instructions-crop-mobile {
      color: var(--white);
      max-width: 40ch;
      text-align: center;
      margin-top: 24vh;
      position: absolute;
      top: 0;
    }
    .document-scan-instructions-mobile {
      color: var(--white);
      max-width: 40ch;
      text-align: center;
      margin-bottom: 25%;
      position: absolute;
      bottom: 0;
      margin-top: 10px;
      z-index: 2;
    }
    .document-target-mobile {
      width: 100% !important;
      z-index: 2;
      padding-top: 6px;
    }
    .zoox-photo-upload {
      div {
        .cropper-container {
          height: 350px !important;
        }
      }
    }
    @media screen and (min-width: 390px) {
      .document-target-mobile {
        width: 105% !important;
        z-index: 2;
        padding-top: 10px;
      }
    }
    @media screen and (max-width: 360px) {
      .document-target-mobile {
        width: 105% !important;
        z-index: 2;
        padding-top: 10px;
      }
    }
    @media screen and (min-width: 412px) {
      .document-target-mobile {
        width: 110% !important;
        z-index: 2;
        padding-top: 12px;
      }
    }
  }
  @media screen and (max-height: 667px) {
    .document-scan-instructions-mobile {
      display: none;
    }
    .document-scan-btn-mobile {
      bottom: 4% !important;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .document-target-mobile {
      visibility: hidden !important;
    }
  }
}
</style>
