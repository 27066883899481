<template>
  <div class="contacts-tabs">
    <p class="body-text">{{ $t("guest.contact_description") }}</p>
    <z-input
      hide-details="auto"
      data-cy="email"
      type="text"
      placeholder="your.email@mail.com"
      :label="guest.isChild ? $t('guest.email_optional') : $t('guest.email')"
      :value="guest.email"
      :error-messages="getErrorMessages(v$.email)"
      @input="$emit('change', { email: $event })"
    />
    <z-phone-input
      v-if="!config.contact?.mobileNumber?.default"
      data-cy="mobile-phone"
      hide-details="auto"
      is-mobile
      :label="
        guest.isChild
          ? $t('guest.mobile_phone_optional')
          : $t('guest.mobile_phone')
      "
      :value="guest.mobilePhoneNumber"
      :countries="countries"
      :current-country="guest.mobilePhoneCountryCode"
      :error-messages="getErrorMessages(v$.mobilePhoneNumber)"
      @change:country="$emit('change', { mobilePhoneCountryCode: $event })"
      @input="$emit('change', { mobilePhoneNumber: $event })"
    />
    <z-phone-input
      v-if="!config.contact?.phoneNumber?.default"
      hide-details="auto"
      :label="$t('guest.phone')"
      :value="guest.phoneNumber"
      :countries="countries"
      :current-country="guest.phoneCountryCode"
      :error-messages="getErrorMessages(v$.phoneNumber)"
      @change:country="$emit('change', { phoneCountryCode: $event })"
      @input="$emit('change', { phoneNumber: $event })"
    />
    <z-checkbox
      color="primary"
      hide-details
      :disabled="!isValidMobilePhone()(guest.mobilePhoneNumber)"
      :label="$t('guest.wpp_optin')"
      :value="guest.optins[0].isChecked"
      @change="$emit('change', { 'optins[0].isChecked': $event })"
    />
    <z-btn
      data-cy="next-btn"
      primary
      width="100%"
      class="next-btn"
      :text="$t('guest.next')"
      :disabled="v$.$dirty && v$.$invalid"
      @click="onNext"
    />
    <z-btn width="100%" :text="$t('guest.back')" @click="$emit('click:back')" />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import countries from "@zoox-ui/data/countries.json"
import { onMounted, toRefs } from "vue"

import { isValidMobilePhone } from "@/validators"

import { usePersonalInfoValidations } from "../useValidations"

/**
 * Componente Mobile Only
 */
export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { guest } = toRefs(props)
    const { contactRules, getErrorMessages } = usePersonalInfoValidations({
      guest,
      config: props.config,
    })

    const v$ = useVuelidate(contactRules, guest)

    const onNext = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        emit("click:next")
      }
    }

    onMounted(() => {
      v$.value.$reset()
    })

    return {
      countries,
      v$,
      getErrorMessages,
      isValidMobilePhone,
      onNext,
    }
  },
}
</script>

<style lang="scss" scoped>
.contacts-tabs {
  display: grid;
  gap: 15px;
}
</style>
