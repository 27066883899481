<template>
  <div>
    <z-card-modal
      v-if="$vuetify.breakpoint.mdAndUp"
      persistent
      :title="$t('documentScanner.scanner_upload_title')"
      width="550px"
      height="auto"
      class="card-modal-document-scanner"
      :opened="opened"
    >
      <template #content>
        <section class="main-content-instructions-modal">
          <div
            v-if="
              personalize.useDefault === true ||
              guest.documents.documentTypeId === 0 ||
              guest.documents.additionalDocumentTypeId === 0
            "
            class="d-flex flex-column align-self-center my-4"
          >
            <div class="text-center mb-2">
              <illustration-document-scan
                v-if="
                  guest.documents.documentTypeId !== 0 ||
                  guest.documents.additionalDocumentTypeId !== 0
                "
                class="image-mobile"
              ></illustration-document-scan>

              <illustration-certidao v-else></illustration-certidao>
            </div>
            <h4 class="body-text text-center px-6">
              {{ $t("reservation.scanner_instruction_title") }}
            </h4>

            <p class="body2 text-center my-5 px-6">
              {{ $t("reservation.scanner_instruction_description") }}
            </p>
            <ul v-for="list in listItemsMobile" :key="list.text">
              <li class="d-flex align-center mb-4">
                <z-icon class="pass-alert-icon mx-2" size="18px" color="green">
                  $check_in_a_circle
                </z-icon>
                <p class="mb-0 body2">{{ list.text }}</p>
              </li>
            </ul>
          </div>
          <div v-else class="text-content-mobile my-4">
            <div class="text-center mb-2">
              <img
                class="figure-content-desktop"
                :src="`${personalize.imageUrl}`"
              />
            </div>
            <div
              :class="classes.div"
              v-html="personalize.text?.currentLang"
            ></div>
          </div>

          <div class="d-flex align-center justify-center" style="width: 100%">
            <z-btn
              class="my-2 mx-2 align-self-end"
              data-cy="button-upload"
              width="30%"
              :text="$t('documentScanner.scanner_upload_button_upload')"
              @click="onSelectImage"
            />
            <z-btn
              class="my-2 mx-2 align-self-end"
              data-cy="button-take-picture"
              primary
              width="30%"
              :text="$t('documentScanner.scanner_upload_button_take-picture')"
              @click="$emit('click:scan-picture')"
            />
          </div>
        </section>
        <div class="btn-container-cancel">
          <z-btn
            class="letsgo-btn my-2"
            width="20%"
            :text="$t('documentScanner.scanner_upload_button_cancel')"
            @click="$emit('click:cancel')"
          />
        </div>
      </template>
    </z-card-modal>
    <z-card
      v-else
      style="position: absolute; top: 0; height: 100vh; left: 0; bottom: 0"
      closable
      width="100%"
      min-height="100vh"
      :opened="opened"
      class="card-container-instructions"
    >
      <template #content>
        <div class="main-content-instructions">
          <section class="mt-5">
            <div
              v-if="
                personalize.useDefault === true ||
                guest.documents.documentTypeId === 0 ||
                guest.additionalDocument.documentTypeId === 0
              "
            >
              <div class="text-center mb-2">
                <illustration-document-scan
                  v-if="
                    guest.documents.documentTypeId !== 0 ||
                    guest.additionalDocument.documentTypeId !== 0
                  "
                ></illustration-document-scan>

                <illustration-certidao v-else></illustration-certidao>
              </div>
              <h4 class="body-text text-center px-6">
                {{ $t("reservation.scanner_instruction_title") }}
              </h4>
              <div
                v-if="$vuetify.breakpoint.smAndDown"
                class="my-6"
                style="width: 280px; border: solid 1px #3333; margin: 0 auto"
              ></div>

              <p class="body2 text-center my-4 px-6">
                {{ $t("reservation.scanner_instruction_description") }}
              </p>
              <ul v-for="list in listItemsMobile" :key="list.text">
                <li class="d-flex align-center mb-6">
                  <z-icon
                    class="pass-alert-icon mx-2"
                    size="18px"
                    color="green"
                  >
                    $check_in_a_circle
                  </z-icon>
                  <p class="mb-0 body2">{{ list.text }}</p>
                </li>
              </ul>
            </div>
            <div v-else class="text-content-mobile my-4">
              <div class="text-center mb-2">
                <img class="figure-content" :src="`${personalize.imageUrl}`" />
              </div>
              <div
                :class="classes.div"
                v-html="personalize.text?.currentLang"
              ></div>
            </div>
          </section>
          <div class="button-container-mobile py-3 px-auto">
            <z-btn
              class="my-2"
              data-cy="button-scan-document-mobile"
              primary
              width="100%"
              :text="$t('recognition.instructions_Button')"
              @click="$emit('click:scan-picture')"
            />
            <z-btn
              class="document-scan-btn my-2"
              :text="$t('documentScanner.scanner_upload_button_upload')"
              width="100%"
              @click="$emit('click:upload-picture')"
            />
            <div
              class="my-2"
              style="
                width: 100%;
                border-top: 1px solid var(--black-300);
                margin: 0 auto;
              "
            ></div>
            <z-btn
              class="document-scan-btn mt-2"
              :text="$t('documentScanner.upload_image_modal_button_back')"
              width="100%"
              @click="$emit('click:cancel')"
            />
            <img
              class="powered-by mt-3"
              src="@/assets/images/poweredby_dark.svg"
              alt="Powered by Zoox"
            />
          </div>
        </div>
      </template>
    </z-card>
  </div>
</template>

<script>
import { computed, ref, toRefs } from "vue"

import i18n from "@/i18n"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    personalize: {
      type: Object,
      default: () => ({}),
    },
    guest: {
      type: Object,
      default: () => ({}),
    },
    currentLang: {
      type: String,
      default: "Pt",
    },
  },
  setup(props, { emit }) {
    const imageInput = ref(null)
    const { guest } = toRefs(props)
    const onSelectImage = () => {
      emit("click:upload-picture")
    }
    const listItems = computed(() => {
      return [
        {
          text: i18n.tc("documentScanner.scanner_upload_list1"),
        },
        {
          text: i18n.tc("documentScanner.scanner_upload_list2"),
        },
        {
          text: i18n.tc("documentScanner.scanner_upload_list3"),
        },
      ]
    })

    const listItemsMobile = computed(() => {
      return [
        {
          text:
            guest.value.documents.documentTypeId !== 0
              ? i18n.tc("reservation.scanner_instructions_list1")
              : i18n.tc("documentScanner.scanner_upload_list4"),
        },
        { text: i18n.tc("reservation.scanner_instructions_list2") },
        { text: i18n.tc("reservation.scanner_instructions_list3") },
        { text: i18n.tc("reservation.scanner_instructions_list4") },
      ]
    })

    return {
      listItems,
      listItemsMobile,
      onSelectImage,
      imageInput,
    }
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 786px) {
  img {
    width: 70%;
    margin: 0 auto;
  }

  .btn-container-cancel {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 10px;
  }

  .image-mobile {
    max-width: 90px;
  }

  .letsgo-btn {
    width: 100px !important;
    margin: 0 15px;
    align-self: flex-end;
  }

  .main-content-instructions-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    width: 90%;
    h4 {
      margin-bottom: 30px;
    }
    ul {
      padding-left: 0 !important;
    }
  }

  .text-content-mobile {
    width: 100%;
    h4 {
      margin-bottom: 30px;
    }
    ul {
      padding-left: 0 !important;
    }
  }

  .body-text {
    padding: 0 20px;
    margin: 0 10px;
  }

  .main-content-instructions {
    height: 100%;
    width: 90%;
  }
  .card-container-instructions {
    width: 100% !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    align-self: center;
  }

  ul {
    padding: 0 15px;
  }
}
.button-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: auto;
  width: 90% !important;
}
.main-content-instructions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 0;
  align-self: center;
}
.powered-by {
  align-self: center;
  justify-self: center;
  color: var(--black-300);
}
.figure-content {
  max-width: 100%;
}
.figure-content-desktop {
  max-width: 90px;
}
</style>

<style module="classes" lang="scss">
.div {
  height: 100%;
  padding: 0 20px;
  hr {
    border: solid 1px #3333 !important;
    margin: 25px 0;
  }

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-size: 0.87rem !important;
    line-height: 20px;
    text-align: center;
    font-weight: 400 !important;
    letter-spacing: 0.15px !important;
    padding: 0 10px;
  }
  h2 {
    font-weight: 300 !important;
    font-size: 1.2rem !important;
    color: var(--black-900);
    letter-spacing: 0.15px !important;
    line-height: 120%;
    text-align: center;
  }

  li,
  ul,
  p {
    font-family: "Roboto";
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 0.8rem !important;
    padding-left: 10px !important;
  }
}
</style>
